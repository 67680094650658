<template>
    <div class="card bs-5 mb-5 container">
        <div class="fl-te-c pb-3">
            <h4 v-html="'VACANCY'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.vacancyAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #disable="{rowData}">
                <checkbox-input v-if="!rowData.loading" v-model="rowData.disable" @input="disable(rowData)" />
                <loading-animation v-else/>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Vacancy" ref="vacancyAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddVacancy @success="formSuccess"></AddVacancy>
        </modal>

        <modal title="Edit Vacancy" ref="vacancyEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditVacancy :initial-data="editingItem" @success="formSuccess"></EditVacancy>
        </modal>

        <delete-modal ref="vacancyDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Vacancy <b v-html="deletingItem && deletingItem.name_of_the_post"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../data/urls';
import AddVacancy from '@/views/admin/vacancy/AddVacancy';
import EditVacancy from '@/views/admin/vacancy/EditVacancy';
import axios from 'secure-axios';

export default {
    name       : 'Vacancy',
    components : { AddVacancy, EditVacancy },
    data () {
        return {
            listUrl      : urls.vacancy.list,
            deleteUrl    : urls.vacancy.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'name_of_the_post',
                    sortField  : 'name_of_the_post',
                    title      : 'Name Of The Post',
                    titleClass : ''
                },
                {
                    name       : '__slot:disable',
                    sortField  : 'disable',
                    title      : 'Disable',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.vacancyAddModal.close();
            refs.vacancyEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.vacancyEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.vacancyDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Vacancy..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.vacancyDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        disable (item) {
            const that = this;
            item.loading = true;
            axios.form(urls.vacancy.addEdit, item).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.$notify('Successfully Updated Vacancy..!', 'Success',
                        {
                            type : 'success'
                        });
                    item.loading = false;
                } else {
                    that.$notify(json.errors, 'Error',
                        {
                            type : 'warning'
                        });
                    item.disable = !item.disable;
                    item.loading = false;
                }
            }).catch(function (err) {
                item.disable = !item.disable;
                item.loading = false;
                console.log('error : ', err);
                that.$notify('Something went wrong..!', 'Success',
                    {
                        type : 'warning'
                    });
            });
        }
    }
};
</script>

<style scoped>

</style>
