<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">

        <validated-input class="col-12" label="Name Of The Post" name="Name Of The Post" v-model="model.name_of_the_post"
                     :rules="{required: true}" :disabled="loading"/>

        <btn class="trn" size="sm" icon="fa fa-save" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'AddVacancy',

    data () {
        return {
            addUrl : urls.vacancy.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Vacancy..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
